// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// import { EthereumProvider } from "@walletconnect/ethereum-provider";
import WalletLink from "walletlink";

// log
import { fetchData } from "../data/dataActions";

const INFURA_ID = "6729c782606a46f380263cf73770c9b8";

// const getProviderWc2 = async () => {
//   const pwc2 = await EthereumProvider.init({
//     projected: "d1c5fcaf1544aea0e3edeba00f1e8521",
//     chains: [5],
//     showQrModal: true,
//     optionalChains: [137, 56, 1],
//     //other config...
//   });
//   return pwc2;
// };
// const providerWc2 = getProviderWc2();

const providerOptions = {
  // walletconnect: {
  //   package: WalletConnectProvider, // required
  //   options: {
  //     infuraId: INFURA_ID, // required
  //     rpc: {
  //       43114: "https://api.avax.network/ext/bc/C/rpc", // AVAX C-Chain
  //       137: "https://rpc-mainnet.maticvigil.com/", // Matic-Chain
  //       56: "https://bsc-dataseed.binance.org/", // Binance-Chain
  //     },
  //   },
  // },
  // walletlink: {
  //   package: WalletLink, // Required
  //   options: {
  //     appName: "Ava Sharks", // Required
  //     infuraId: "", // Required unless you provide a JSON RPC url; see `rpc` below
  //     rpc: "https://api.avax.network/ext/bc/C/rpc", // Optional if `infuraId` is provided; otherwise it's required
  //     chainId: 43114, // Optional. It defaults to 1 if not provided
  //     appLogoUrl: null, // Optional. Application logo image URL. favicon is used if unspecified
  //     darkMode: true, // Optional. Use dark theme, defaults to false
  //   },
  // },
};
const convertToHexWith0x = (id) => {
  // IDを16進数に変換
  const hexValue = id.toString(16);

  // "0x"を追加
  const hexWith0x = `0x${hexValue}`;

  return hexWith0x;
};

// delete providerOptions.walletconnect;
// delete providerOptions.walletlink;

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    try {
      // ウォレットがインストールされているかチェック
      if (typeof window.ethereum !== "undefined") {
        // ウォレットがインストールされている場合の処理
        // ...
        const abiResponse = await fetch("/config/abi.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const abi = await abiResponse.json();
        const configResponse = await fetch("/config/config.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const CONFIG = await configResponse.json();
        localStorage.removeItem("walletconnect");
        localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
        const web3Modal = new Web3Modal({
          network: "mainnet", // optional
          cacheProvider: false, // optional
          providerOptions, // required
          // providerWc2,
        });
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);

        Web3EthContract.setProvider(provider);
        const accounts = await web3.eth.getAccounts();
        const networkId = await provider.request({
          method: "net_version",
        });
        if (Number(networkId) == Number(CONFIG.NETWORK.ID)) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              // account: "0x63E49E3863F37291488B7dCF615722885660BF58",
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          provider.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          provider.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          if (window.ethereum.isMetaMask) {
            //ネットワーク切り替え部分
            try {
              // check if the chain to connect to is installed
              await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: convertToHexWith0x(CONFIG.NETWORK.ID) }], // goerliのID（16進数）
                // params: [{ chainId: '0x4' }], // rinkebyのID（16進数）
                // params: [{ chainId: "0x5" }], // goerliのID（16進数）
              });
            } catch (error) {
              // エラーの場合、チェーン追加を試みる
              if (error.code === 4902) {
                try {
                  await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                      {
                        chainId: convertToHexWith0x(CONFIG.NETWORK.ID), //goerli
                        // chainId: '0x4', //rinkeby
                        // chainId: "0x5", //goerli
                        // rpcUrl: 'https://mainnet-infura.brave.com/',
                      },
                    ],
                  });
                } catch (addError) {
                  console.error(addError);
                }
              }
              console.error(error);
            }
            // dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`)); //コメントアウト
            alert(
              "ネットワークを切り替えました。もう一度CONNECTしてみてください。"
            ); //アラート表示
            dispatch(connectFailed(`もう一度CONNECTしてみてください。`)); //メッセ表示
            //ネットワーク切り替え部分終了
          }
        }
      } else {
        // ウォレットがインストールされていない場合の処理
        alert("MetaMaskをインストールしてください。");
        dispatch(connectFailed("MetaMaskをインストールしてください。"));
        return; // ここで処理を終了
      }
    } catch (err) {
      console.log("error", err, " message", err.message);
      if (
        typeof err !== "undefined" &&
        typeof err.message !== "undefined" &&
        err.message.includes("User Rejected")
      ) {
        dispatch(connectFailed("接続を拒否されました"));
      } else if (
        (typeof err === "string" || err instanceof String) &&
        err.includes("Modal closed by user")
      ) {
        dispatch(connectFailed("モーダルウィンドウが閉じられました"));
      } else if (
        typeof err !== "undefined" &&
        typeof err.message !== "undefined" &&
        err.message.includes("isMetaMask")
      ) {
        dispatch(connectFailed(`正しいネットワークでつなげてください`)); //コメントアウト
      } else {
        dispatch(connectFailed("Something went wrong."));
      }
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
