import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataPhase } from "./redux/dataphase/dataphaseActions";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import { Canvas } from "@react-three/fiber";
import * as Loader from "react-loader-spinner";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const BackContainer = styled.div`
  display: block;
  background-image: url(/config/images/dn2.gif);
  position: fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 31vh;
  background-size: 500px;
  width: 100%;
  height: 100%;
  z-index: -100;
`;

export const StyledButtonFixed = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.2rem 0.5rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(181, 255, 20, 0.9);
  :active {
    padding: 0.3rem 0.6rem;
  }
  :hover {
    color: purple;
    opacity: 0.8;
  }
`;

export const StyledButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.6rem 4rem;
  position: relative;
  /* overflow: hidden; */
  background-color: rgba(181, 255, 20, 0.9);
  ${({ bgImage }) =>
    bgImage &&
    `
    color: rgba(0, 0, 0, 0);
    background-image: url(${bgImage});
    background-size: cover;
    background-position:center;
  `}

  :active {
    padding: 0.3rem 2rem;
  }
  :hover {
    ${({ bgImage }) =>
      bgImage &&
      `
    color: rgba(0, 0, 0, 0);
  `}
    opacity: 0.8;
  }
`;
export const StyledConnectButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #000;
  min-width: 170px;
  min-height: 51px;
  font-size: 3rem;
  font-weight: 800;
  border-radius: 100px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255);
  background-size: cover;
  /* animation: gradation 10.7s ease-in infinite; */
  :active {
    padding: 0.3rem 2rem;
    background-image: url("/config/images/connect.hover.jpg");
  }
  :hover {
    opacity: 0.9;
    /* background-image: url("/config/images/connect.hover.jpg"); */
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #333;
  font-size: 2rem;
  border-radius: 10px;
  padding: 0.1rem 0.1rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-image: url("/config/images/main/exo1.png");
  background-size: cover;
  background-position: top;
  padding: 5rem;
  /* height: 100vh; */
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const OperationContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-size: cover;
  background-position: top;
  padding: 5rem;
  height: 10vh;
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const StyledIncDecButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  font-weight: 700;
  border-radius: 10px;
  margin: 0 4rem;
  /* border: solid 1px #333; */
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-weight: bold;
  font-size: 2rem;
  color: #333;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3); */
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  :hover {
    opacity: 0.6;
    transition: opacity 400ms;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;

  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 100px;
  @media (min-width: 767px) {
    width: 500px;
    height: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB !important;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    opacity: 0.8;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const dataphase = useSelector((state) => state.dataphase);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWL, setmintAmountWL] = useState(1);
  const [mintAmountAL0, setMintAmountAL0] = useState(1);
  const [mintAmountAL1, setMintAmountAL1] = useState(1);
  const [mintAmountAL2, setMintAmountAL2] = useState(1);
  const [mintAmountAL3_0, setMintAmountAL3_0] = useState(1);
  const [mintAmountAL3_1, setMintAmountAL3_1] = useState(1);
  const [mintAmountAL3_2, setMintAmountAL3_2] = useState(1);
  const [mintAmountAL3_3, setMintAmountAL3_3] = useState(1);
  const [mintAmountAL3_4, setMintAmountAL3_4] = useState(1);
  const [mintAmountAL4_0, setMintAmountAL4_0] = useState(1);
  const [mintAmountAL4_1, setMintAmountAL4_1] = useState(1);
  const [mintAmountAL4_2, setMintAmountAL4_2] = useState(1);
  const [mintAmountAL4_3, setMintAmountAL4_3] = useState(1);
  const [mintAmountAL4_4, setMintAmountAL4_4] = useState(1);

  const [minted, setminted] = useState(0);
  const [mintedWL, setmintedWL] = useState(0);

  const [allowlistProof0, setAllowlistProof0] = useState("");
  const [allowlistValid0, setAllowlistValid0] = useState(false);
  const [allowlistProof1, setAllowlistProof1] = useState("");
  const [allowlistValid1, setAllowlistValid1] = useState(false);
  const [allowlistProof2, setAllowlistProof2] = useState("");
  const [allowlistValid2, setAllowlistValid2] = useState(false);
  const [allowlistProof3, setAllowlistProof3] = useState("");
  const [allowlistValid3, setAllowlistValid3] = useState(false);
  const [allowlistProof4, setAllowlistProof4] = useState("");
  const [allowlistValid4, setAllowlistValid4] = useState(false);

  const [allowlistRoot0, setAllowlistRoot0] = useState("");
  const [allowlistRoot1, setAllowlistRoot1] = useState("");
  const [allowlistRoot2, setAllowlistRoot2] = useState("");
  const [allowlistRoot3, setAllowlistRoot3] = useState("");
  const [allowlistRoot4, setAllowlistRoot4] = useState("");

  const [canMintAl0, setcanMintAl0] = useState(3);
  const [canMintAl1, setcanMintAl1] = useState(1);
  const [canMintAl2, setcanMintAl2] = useState(1);
  const [canMintAl3_0, setcanMintAl3_0] = useState(1);
  const [canMintAl3_1, setcanMintAl3_1] = useState(1);
  const [canMintAl3_2, setcanMintAl3_2] = useState(1);
  const [canMintAl3_3, setcanMintAl3_3] = useState(1);
  const [canMintAl3_4, setcanMintAl3_4] = useState(5);

  const [pcMinted_0, setpcMinted_0] = useState(1);
  const [pcMinted_1, setpcMinted_1] = useState(1);
  const [pcMinted_2, setpcMinted_2] = useState(1);
  const [pcMinted_3, setpcMinted_3] = useState(1);
  const [pcMinted_4, setpcMinted_4] = useState(5);

  const [canMintAl4_0, setcanMintAl4_0] = useState(10);
  const [canMintAl4_1, setcanMintAl4_1] = useState(10);
  const [canMintAl4_2, setcanMintAl4_2] = useState(10);
  const [canMintAl4_3, setcanMintAl4_3] = useState(10);
  const [canMintAl4_4, setcanMintAl4_4] = useState(10);

  const [mintedAL0, setMintedAL0] = useState(0);
  const [mintedAL1, setMintedAL1] = useState(0);
  const [mintedAL2, setMintedAL2] = useState(0);
  const [mintedAL3_0, setMintedAL3_0] = useState(0);
  const [mintedAL3_1, setMintedAL3_1] = useState(0);
  const [mintedAL3_2, setMintedAL3_2] = useState(0);
  const [mintedAL3_3, setMintedAL3_3] = useState(0);
  const [mintedAL3_4, setMintedAL3_4] = useState(0);
  const [mintedAL4_0, setMintedAL4_0] = useState(0);
  const [mintedAL4_1, setMintedAL4_1] = useState(0);
  const [mintedAL4_2, setMintedAL4_2] = useState(0);
  const [mintedAL4_3, setMintedAL4_3] = useState(0);
  const [mintedAL4_4, setMintedAL4_4] = useState(0);

  const [revealNum, setrevealNum] = useState("");

  const [muteCtr, setMuteCtr] = useState(true);
  const [playCtr, setPlayCtr] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(true);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const fetchDatas = (account) => {
  //   dispatch(fetchData(account));
  //   dispatch(fetchDataSub(account));
  // };

  const changeRevealNum = (event) => {
    const newVal = event.target.value;
    if (revealNum != event.target.value) {
      setrevealNum(newVal);
      setrevealNum((newVal) => {
        //初回クリック時のpre_countは1
        console.dir(`pass:${newVal}`);
        return newVal;
      });
    }
  };

  const getAllowlist = (_alId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let allowlist = require("./data/al" + _alId + ".json");

      let hashedAddresses = allowlist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isAllowlisted(blockchain.account, _alId, proof)
        .call()
        .then((receipt) => {
          switch (_alId) {
            case 0:
              setAllowlistProof0(proof);
              setAllowlistValid0(receipt);
              // if (canMintAlo > 0) {
              //   setMintable0(canMintAl0 - mintedAL0);
              // }
              console.log("AL" + _alId + ":proof/" + allowlistProof0);
              break;
            case 1:
              setAllowlistProof1(proof);
              setAllowlistValid1(receipt);
              // if (canMintAl1 > 0) {
              //   setMintable1(canMintAl1 - mintedAL1);
              // }
              console.log("AL" + _alId + ":proof/" + allowlistProof1);
              break;
            case 2:
              setAllowlistProof2(proof);
              setAllowlistValid2(receipt);
              // if (canMintAl2 > 0) {
              //   setMintable2(canMintAl2 - mintedAL2);
              // }
              console.log("AL" + _alId + ":proof/" + allowlistProof2);
              break;
            case 3:
              setAllowlistProof3(proof);
              setAllowlistValid3(receipt);
              // if (canMintAl3 > 0) {
              //   setMintable3(canMintAl3 - mintedAL3);
              // }
              console.log("AL" + _alId + ":proof/" + allowlistProof3);
              break;
            case 4:
              setAllowlistProof4(proof);
              setAllowlistValid4(receipt);
              // if (canMintAl4 > 0) {
              //   setMintable4(canMintAl4 - mintedAL4);
              // }
              console.log("AL" + _alId + ":proof/" + allowlistProof4);
              break;

            default:
              console.log("not found PhaseID");
          }
          console.log("AL" + _alId + ":valid:" + [receipt]);

          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setAllowlistProof(proof);
      // setAllowlistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      switch (_alId) {
        case 0:
          setAllowlistRoot0(root);
          console.log("AL0" + _alId + ":proof/" + allowlistProof0);
          break;
        case 1:
          setAllowlistRoot1(root);
          console.log("AL1" + _alId + ":proof/" + allowlistProof1);
          break;
        case 2:
          setAllowlistRoot2(root);
          console.log("AL2" + _alId + ":proof/" + allowlistProof2);
          break;
        case 3:
          setAllowlistRoot3(root);
          console.log("AL3" + _alId + ":proof/" + allowlistProof3);
          break;
        case 4:
          setAllowlistRoot4(root);
          console.log("AL4" + _alId + ":proof/" + allowlistProof4);
          break;
        default:
          console.log("not found PhaseID");
      }
      console.log("AL" + _alId + ":root/" + root);
      // console.log(whitelistValid);
    }
  };

  // setPublicSaleEnable
  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setALSaleEnable = (_phaseId, flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setAllowlistSaleEnable(_phaseId, flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にAL" + _phaseId + "セール開始されました！")
            : setFeedback("正常にAL" + _phaseId + "セール停止されました！");
        }
        setClaimingNft(false);
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };

  const setReveal = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setReveal(revealNum)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にリビールされました！openseaでご確認ください。`
        );
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setBaseUrl = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ベースURL設定します ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setBaseURI(
        "https://arweave.net/k8WJF26qR0Vp1Ir-X1xInbOA_5Z8tR0sdSK2AazmcGc/"
      )
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常にベースURL設定されました！`
        );
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const alRegist = (_phaseId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let alRoot;

    switch (_phaseId) {
      case 0:
        alRoot = allowlistRoot0;
        break;
      case 1:
        alRoot = allowlistRoot1;
        break;
      case 2:
        alRoot = allowlistRoot2;
        break;
      case 3:
        alRoot = allowlistRoot3;
        break;
      case 4:
        alRoot = allowlistRoot4;
        break;
      default:
        console.log("not found PhaseID");
    }
    setFeedback(`SETMERKLE AL${_phaseId}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setMerkleRootAlWithId(_phaseId, alRoot)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にAL" + _phaseId + "登録しました！");
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const checkMinted = (_pcId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .psMinted(blockchain.account, _pcId)
        .call()
        .then((receipt) => {
          setminted(receipt);
          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const checkMintedAL = (_alId, _pcId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .alIdMinted(_alId, blockchain.account, _pcId)
        .call()
        .then((receipt) => {
          switch (_alId) {
            case 0:
              setMintedAL0(receipt);
              break;
            case 1:
              setMintedAL1(receipt);
              break;
            case 2:
              setMintedAL2(receipt);
              break;
            case 3:
              switch (_pcId) {
                case 0:
                  setMintedAL3_0(receipt);
                  break;
                case 1:
                  setMintedAL3_1(receipt);
                  break;
                case 2:
                  setMintedAL3_2(receipt);
                  break;
                case 3:
                  setMintedAL3_3(receipt);
                  break;
                case 4:
                  setMintedAL3_4(receipt);
                  break;
                default:
                  console.log("not found _pcId");
              }
              break;
            case 4:
              switch (_pcId) {
                case 0:
                  setMintedAL4_0(receipt);
                  break;
                case 1:
                  setMintedAL4_1(receipt);
                  break;
                case 2:
                  setMintedAL4_2(receipt);
                  break;
                case 3:
                  setMintedAL4_3(receipt);
                  break;
                case 4:
                  setMintedAL4_4(receipt);
                  break;
                default:
                  console.log("not found _pcId" + _alId + ":" + _pcId);
              }
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const getMaxMintAL = (_alId, _pcId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .getMaxMint(_alId, _pcId, blockchain.account)
        .call()
        .then((receipt) => {
          switch (_alId) {
            case 0:
              setcanMintAl0(receipt);
              break;
            case 1:
              setcanMintAl1(receipt);
              break;
            case 2:
              setcanMintAl2(receipt);
              break;
            case 3:
              switch (_pcId) {
                case 0:
                  setcanMintAl3_0(receipt);
                  break;
                case 1:
                  setcanMintAl3_1(receipt);
                  break;
                case 2:
                  setcanMintAl3_2(receipt);
                  break;
                case 3:
                  setcanMintAl3_3(receipt);
                  break;
                case 4:
                  setcanMintAl3_4(receipt);
                  break;
                default:
                  console.log("not found _pcId");
              }
              break;
            case 4:
              switch (_pcId) {
                case 0:
                  setcanMintAl4_0(receipt);
                  break;
                case 1:
                  setcanMintAl4_1(receipt);
                  break;
                case 2:
                  setcanMintAl4_2(receipt);
                  break;
                case 3:
                  setcanMintAl4_3(receipt);
                  break;
                case 4:
                  setcanMintAl4_4(receipt);
                  break;
                default:
                  console.log("not found _pcId" + _alId + ":" + _pcId);
              }
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const getPcMinted = (_pcId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .pcMinted(_pcId)
        .call()
        .then((receipt) => {
          switch (_pcId) {
            case 0:
              setpcMinted_0(receipt);
              break;
            case 1:
              setpcMinted_1(receipt);
              break;
            case 2:
              setpcMinted_2(receipt);
              break;
            case 3:
              setpcMinted_3(receipt);
              break;
            case 4:
              setpcMinted_4(receipt);
              break;
            default:
              console.log("not found _pcId");
          }
        });
    }
  };

  const claimNFTsAl = async (_phaseId, _pcId) => {
    let cost = CONFIG.WEI_COSTAL0;
    let amount = 0;
    let allowlistProof = "";
    switch (_phaseId) {
      case 0:
        allowlistProof = allowlistProof0;
        amount = mintAmountAL0;
        cost = CONFIG.WEI_COSTAL0;
        break;
      case 1:
        allowlistProof = allowlistProof1;
        amount = mintAmountAL1;
        cost = CONFIG.WEI_COSTAL1;
        break;
      case 2:
        allowlistProof = allowlistProof2;
        amount = mintAmountAL2;
        cost = CONFIG.WEI_COSTAL2;
        break;
      case 3:
        allowlistProof = allowlistProof3;
        switch (_pcId) {
          case 0:
            amount = mintAmountAL3_0;
            cost = CONFIG.WEI_COSTAL3_0;
            break;
          case 1:
            amount = mintAmountAL3_1;
            cost = CONFIG.WEI_COSTAL3_1;
            break;
          case 2:
            amount = mintAmountAL3_2;
            cost = CONFIG.WEI_COSTAL3_2;
            break;
          case 3:
            amount = mintAmountAL3_3;
            cost = CONFIG.WEI_COSTAL3_3;
            break;
          case 4:
            amount = mintAmountAL3_4;
            cost = CONFIG.WEI_COSTAL3_4;
            break;
          default:
            console.log("not found _pcId" + _alId + ":" + _pcId);
        }
        break;
      case 4:
        allowlistProof = allowlistProof4;
        switch (_pcId) {
          case 0:
            amount = mintAmountAL4_0;
            cost = CONFIG.WEI_COSTAL4_0;
            break;
          case 1:
            amount = mintAmountAL4_1;
            cost = CONFIG.WEI_COSTAL4_1;
            break;
          case 2:
            amount = mintAmountAL4_2;
            cost = CONFIG.WEI_COSTAL4_2;
            break;
          case 3:
            amount = mintAmountAL4_3;
            cost = CONFIG.WEI_COSTAL4_3;
            break;
          case 4:
            amount = mintAmountAL4_4;
            cost = CONFIG.WEI_COSTAL4_4;
            break;
          default:
            console.log("not found _pcId" + _alId + ":" + _pcId);
        }
        break;
      default:
        console.log("not found PhaseID");
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1.2;
    // gasMulti = 0.7 * amount;
    // gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    // let method = await blockchain.smartContract.methods.allowlistMint(
    //   _phaseId,
    //   _pcId,
    //   amount,
    //   blockchain.account,
    //   allowlistProof
    // );
    // let gas = await method.estimateGas({
    //   from: blockchain.account,
    //   value: totalCostWei,
    // });

    let method;
    let gas;
    try {
      method = await blockchain.smartContract.methods.allowlistMint(
        _phaseId,
        _pcId,
        amount,
        blockchain.account,
        allowlistProof
      );
      gas = await method.estimateGas({
        from: blockchain.account,
        value: totalCostWei,
      });
      // ここで他の処理を行う
    } catch (error) {
      // エラーメッセージから特定の部分を抽出
      let errorMessage = error.message;
      console.log(error);
      // 'execution reverted:' という文字列が含まれている場合、その部分だけを抽出
      if (errorMessage.indexOf("No more parcels") !== -1) {
        alert("売り切れです！(SOLD OUT!)");
      } else if (errorMessage.indexOf("insufficient funds") !== -1) {
        alert("ETHが足りません！(insufficient funds)");
      } else {
        // 期待する形式のメッセージがない場合は、全体を表示
        alert(errorMessage);
      }
      return;
    }

    gas = Math.ceil(Number(gas) * gasMulti);

    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * gasMulti);
    console.log("gasPrice: " + gasPrice);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME} minting....`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .allowlistMint(
        _phaseId,
        _pcId,
        amount,
        blockchain.account,
        allowlistProof
      )
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ERROR....もう一度お試しください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback("minted.check on opensea.");
        setClaimingNft(false);
        checkMintedAL(_phaseId, _pcId);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = (_pcId) => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(_pcId, mintAmount, blockchain.account)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `minted.check on opensea.`
        );
        setClaimingNft(false);
        checkMinted(_pcId);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };
  function formatAddress(address) {
    if (!address || address.length < 10) {
      return address;
    }
    return `${address.substring(0, 5)}...${address.substring(
      address.length - 5
    )}`;
  }

  const decrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const incrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 + diffNum;
    let maxNum = canMintAl0 - mintedAL0;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const decrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const incrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 + diffNum;
    let maxNum = canMintAl1 - mintedAL1;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const decrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const incrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 + diffNum;
    let maxNum = canMintAl2 - mintedAL2;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const decrementMintAmountAL3_0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_0 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL3_0(newMintAmountAL);
  };

  const incrementMintAmountAL3_0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_0 + diffNum;
    let maxNum = canMintAl3_0 - mintedAL3_0;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL3_0(newMintAmountAL);
  };

  const decrementMintAmountAL3_1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_1 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL3_1(newMintAmountAL);
  };

  const incrementMintAmountAL3_1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_1 + diffNum;
    let maxNum = canMintAl3_1 - mintedAL3_1;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL3_1(newMintAmountAL);
  };
  const decrementMintAmountAL3_2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_2 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL3_2(newMintAmountAL);
  };

  const incrementMintAmountAL3_2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_2 + diffNum;
    let maxNum = canMintAl3_2 - mintedAL3_2;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL3_2(newMintAmountAL);
  };

  const decrementMintAmountAL3_3 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_3 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL3_3(newMintAmountAL);
  };

  const incrementMintAmountAL3_3 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_3 + diffNum;
    let maxNum = canMintAl3_3 - mintedAL3_3;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL3_3(newMintAmountAL);
  };
  const decrementMintAmountAL3_4 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_4 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL3_4(newMintAmountAL);
  };

  const incrementMintAmountAL3_4 = (diffNum) => {
    let newMintAmountAL = mintAmountAL3_4 + diffNum;
    let maxNum = canMintAl3_4 - mintedAL3_4;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL3_4(newMintAmountAL);
  };

  const decrementMintAmountAL4_0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_0 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL4_0(newMintAmountAL);
  };

  const incrementMintAmountAL4_0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_0 + diffNum;
    let maxNum = canMintAl4_0 - mintedAL4_0;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL4_0(newMintAmountAL);
  };
  const decrementMintAmountAL4_1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_1 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL4_1(newMintAmountAL);
  };

  const incrementMintAmountAL4_1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_1 + diffNum;
    let maxNum = canMintAl4_1 - mintedAL4_1;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL4_1(newMintAmountAL);
  };

  const decrementMintAmountAL4_2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_2 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL4_2(newMintAmountAL);
  };

  const incrementMintAmountAL4_2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_2 + diffNum;
    let maxNum = canMintAl4_2 - mintedAL4_2;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL4_2(newMintAmountAL);
  };

  const decrementMintAmountAL4_3 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_3 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL4_3(newMintAmountAL);
  };

  const incrementMintAmountAL4_3 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_3 + diffNum;
    let maxNum = canMintAl4_3 - mintedAL4_3;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL4_3(newMintAmountAL);
  };
  const decrementMintAmountAL4_4 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_4 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL4_4(newMintAmountAL);
  };

  const incrementMintAmountAL4_4 = (diffNum) => {
    let newMintAmountAL = mintAmountAL4_4 + diffNum;
    let maxNum = canMintAl4_4 - mintedAL4_4;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL4_4(newMintAmountAL);
  };
  const getData = async () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
      dispatch(fetchDataSub(blockchain.account));
      dispatch(fetchDataBase(blockchain.account));
      dispatch(fetchDataPhase(blockchain.account));

      // let pendings = await blockchain.web3.eth.getTransactionCount(
      //   CONFIG.CONTRACT_ADDRESS,
      //   "pending"
      // );

      // console.dir("pending:", pendings);
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    }
    getData();
    getAllowlist(0);
    getAllowlist(1);
    getAllowlist(2);
    getAllowlist(3);
    getAllowlist(4);

    getMaxMintAL(0, 4);
    getMaxMintAL(1, 3);
    getMaxMintAL(2, 2);
    getMaxMintAL(3, 0);
    getMaxMintAL(3, 1);
    getMaxMintAL(3, 2);
    getMaxMintAL(3, 3);
    getMaxMintAL(3, 4);
    getMaxMintAL(4, 0);
    getMaxMintAL(4, 1);
    getMaxMintAL(4, 2);
    getMaxMintAL(4, 3);
    getMaxMintAL(4, 4);

    checkMintedAL(0, 4);
    checkMintedAL(1, 3);
    checkMintedAL(2, 2);
    checkMintedAL(3, 0);
    checkMintedAL(3, 1);
    checkMintedAL(3, 2);
    checkMintedAL(3, 3);
    checkMintedAL(3, 4);
    checkMintedAL(4, 0);
    checkMintedAL(4, 1);
    checkMintedAL(4, 2);
    checkMintedAL(4, 3);
    checkMintedAL(4, 4);

    getPcMinted(0);
    getPcMinted(1);
    getPcMinted(2);
    getPcMinted(3);
    getPcMinted(4);
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };
  useEffect(() => {
    getConfig();
    getInit();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  useEffect(() => {
    setrevealNum(datasub.revealed);
  }, [datasub.revealed]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        id="containerBox"
        ai={"center"}
        style={{
          padding: 0,
          backgroundColor: "rgba(0,0,0,0.8)",
          minHeight: "100vh",
        }}
      >
        <ResponsiveWrapper
          flex={1}
          style={{ padding: "5rem 0" }}
          className={"kv"}
          test
        >
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <MainContainer
                flex={2}
                jc={"center"}
                ai={"center"}
                style={{ width: "100%", overflowX: "hidden" }}
              >
                <span className="features">
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    完売！！ありがとうございます！！今後ともよろしくお願いします。
                  </s.TextTitle>
                </span>
              </MainContainer>
            </>
          ) : (
            <>
              {blockchain.account === "" ||
              blockchain.smartContract === null ||
              blockchain.account === undefined ? (
                <MainContainer
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{ width: "100%", overflowX: "hidden" }}
                >
                  <span className="features">
                    <s.Container
                      ai={"center"}
                      jc={"center"}
                      style={{ height: "100vh" }}
                    >
                      {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription> */}
                      {/* <CircleTextLogo /> */}
                      <s.SpacerSmall />

                      <StyledConnectButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT
                      </StyledConnectButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  </span>
                </MainContainer>
              ) : (
                <>
                  <s.Container flex={1} jc={"center"} ai={"center"}>
                    <MainContainer
                      flex={2}
                      jc={"center"}
                      ai={"center"}
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        backgroundSize: "500px",
                        padding: 5,
                        color: "white",
                      }}
                    >
                      <span className="features">
                        <s.Container ai={"center"}>
                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              color: "white",
                              fontSize: "2rem",
                            }}
                          >
                            STARTLAND
                          </s.TextTitle>
                          <s.SpacerLarge />
                          <s.SpacerLarge />

                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              fontSize: 50,
                              fontWeight: "bold",
                              color: "var(--accent-text)",
                            }}
                          >
                            {("00000" + data.totalSupply).slice(-5)}
                          </s.TextTitle>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--primary-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.SCAN_LINK}
                            >
                              {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerSmall />
                          {"0x637d25D0769f747B2742A04d249802dA85395970" ==
                            blockchain.account ||
                          "0xB4250F715995683c6EA5BC7c5e2CDF9b1601ba3f" ==
                            blockchain.account ? (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--primary-text)",
                                }}
                              >
                                {"20x20 rest:" + (pcMinted_0 - 17)}
                                {pcMinted_0 >= 17 && "/SOLD OUT!"}
                              </s.TextDescription>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--primary-text)",
                                }}
                              >
                                {"10x10 rest:" + (pcMinted_1 - 65)}
                                {pcMinted_1 >= 65 && "/SOLD OUT!"}
                              </s.TextDescription>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--primary-text)",
                                }}
                              >
                                {"5x5 rest:" + (pcMinted_2 - 278)}
                                {pcMinted_2 >= 278 && "/SOLD OUT!"}
                              </s.TextDescription>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--primary-text)",
                                }}
                              >
                                {"3x3 rest:" + (pcMinted_3 - 1500)}
                                {pcMinted_3 >= 1500 && "/SOLD OUT!"}
                              </s.TextDescription>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--primary-text)",
                                }}
                              >
                                {"1x1 rest:" + (pcMinted_4 - 15500)}
                                {pcMinted_4 >= 15500 && "/SOLD OUT!"}
                              </s.TextDescription>
                            </>
                          ) : (
                            <></>
                          )}

                          <s.SpacerLarge />

                          <s.SpacerXSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Gas fee is required.
                          </s.TextDescription>

                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerLarge />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            YOUR ADDRESS: {formatAddress(blockchain.account)}
                          </s.TextDescription>

                          <s.SpacerLarge />

                          <s.SpacerLarge />

                          {/* セール状況ここから */}
                          {/* セール状況ここから */}
                          {/* セール状況ここから */}
                          {/* セール状況ここから */}

                          {(allowlistValid0 && database.isAlEnabled0) ||
                          (allowlistValid1 && database.isAlEnabled1) ||
                          (allowlistValid2 && database.isAlEnabled2) ||
                          (allowlistValid3 && database.isAlEnabled3) ||
                          (allowlistValid4 && database.isAlEnabled4) ||
                          data.isPublicSaleEnabled ? (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {allowlistValid0 && database.isAlEnabled0
                                  ? "<FREE MINT 1x1>　"
                                  : ""}
                                {allowlistValid1 && database.isAlEnabled1
                                  ? "<FREE MINT 3x3>　"
                                  : ""}
                                {allowlistValid2 && database.isAlEnabled2
                                  ? "<FREE MINT 5x5>　"
                                  : ""}
                                {allowlistValid3 && database.isAlEnabled3
                                  ? "<AL>　"
                                  : ""}
                                {allowlistValid4 && database.isAlEnabled4
                                  ? "<FCFS>　"
                                  : ""}
                                {data.isPublicSaleEnabled ? "<PUBLIC>　" : ""}
                                <s.SpacerLarge />

                                {"Now On Sale!"}
                              </s.TextTitle>
                              {/* <s.SpacerLarge />
                              <s.SpacerLarge /> */}
                              <s.SpacerLarge />
                            </>
                          ) : (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"Wait For Sale..."}
                              </s.TextTitle>
                            </>
                          )}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}

                          {/* FREE1x1所有者の方ここから */}
                          {/* FREE1x1所有者の方ここから */}
                          {/* FREE1x1所有者の方ここから */}
                          {/* FREE1x1所有者の方ここから */}
                          {allowlistValid0 ? (
                            <>
                              {database.isAlEnabled0 ? (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FREE - Land 1x1"}
                                  </s.TextDescription>
                                  {pcMinted_4 >= 15500 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl0 == mintedAL0 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FREE1x1 Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL0 *
                                                  mintAmountAL0 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />

                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL0(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL0}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL0(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(0, 4);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL0 + " MINT(1x1)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"5f805fc8-1fd3-41c1-ae6e-4f5dc095f649"}?quantity=${mintAmountAL0}&recipientAddress=${
                                                blockchain.account
                                              }`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL0 +
                                                    " MINT(1x1)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL0}
                                            {"/"}
                                            {canMintAl0}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* FREE1x1所有者の方ここまで */}
                          {/* FREE1x1所有者の方ここまで */}
                          {/* FREE1x1所有者の方ここまで */}
                          {/* FREE1x1所有者の方ここまで */}

                          {/* FREE3x3所有者の方ここから */}
                          {/* FREE3x3所有者の方ここから */}
                          {/* FREE3x3所有者の方ここから */}
                          {/* FREE3x3所有者の方ここから */}
                          {allowlistValid1 ? (
                            <>
                              {database.isAlEnabled1 ? (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FREE - Land 3x3"}
                                  </s.TextDescription>

                                  {pcMinted_3 >= 1500 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl1 == mintedAL1 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FREE3x3 Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL1 *
                                                  mintAmountAL1 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL1(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL1}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL1(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(1, 3);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL1 + " MINT(3x3)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"a5cc807d-f04d-4d31-9075-0799189083cb"}?quantity=${mintAmountAL1}&recipientAddress=${
                                                blockchain.account
                                              }`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL1 +
                                                    " MINT(3x3)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL1}
                                            {"/"}
                                            {canMintAl1}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* FREE3x3所有者の方ここまで */}
                          {/* FREE3x3所有者の方ここまで */}
                          {/* FREE3x3所有者の方ここまで */}
                          {/* FREE3x3所有者の方ここまで */}

                          {/* FREE5x5所有者の方ここから */}
                          {/* FREE5x5所有者の方ここから */}
                          {/* FREE5x5所有者の方ここから */}
                          {/* FREE5x5所有者の方ここから */}
                          {allowlistValid2 ? (
                            <>
                              {database.isAlEnabled2 ? (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FREE - Land 5x5"}
                                  </s.TextDescription>

                                  {pcMinted_2 >= 278 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl2 == mintedAL2 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FREE5x5 Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL2 *
                                                  mintAmountAL2 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL2(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL2}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL2(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(2, 2);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL2 + " MINT(5x5)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"6801f7bb-0ea3-469a-9681-b064c3e8b974"}?quantity=${mintAmountAL2}&recipientAddress=${
                                                blockchain.account
                                              }`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL2 +
                                                    " MINT(5x5)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL2}
                                            {"/"}
                                            {canMintAl2}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* FREE5x5所有者の方ここまで */}
                          {/* FREE5x5所有者の方ここまで */}
                          {/* FREE5x5所有者の方ここまで */}
                          {/* FREE5x5所有者の方ここまで */}

                          {/* AL所有者の方ここから */}
                          {/* AL所有者の方ここから */}
                          {/* AL所有者の方ここから */}
                          {/* AL所有者の方ここから */}
                          {allowlistValid3 ? (
                            <>
                              {database.isAlEnabled3 ? (
                                <>
                                  {/* AL-20x20ここから */}
                                  {/* AL-20x20ここから */}
                                  {/* AL-20x20ここから */}
                                  {/* AL-20x20ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"AL - Land 20x20"}
                                  </s.TextDescription>
                                  {pcMinted_0 >= 17 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl3_0 == mintedAL3_0 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"AL Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL3_0 *
                                                  mintAmountAL3_0 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL3_0(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL3_0}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL3_0(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(3, 0);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL3_0 +
                                                  " MINT(20x20)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"a0b6842e-3ad8-4154-af6c-67d9e1ee0e9d"}?quantity=${mintAmountAL3_0}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=0&amountInWei=${1000000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL3_0 +
                                                    " MINT(20x20)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL3_0}
                                            {"/"}
                                            {canMintAl3_0}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* AL-10x10ここから */}
                                  {/* AL-10x10ここから */}
                                  {/* AL-10x10ここから */}
                                  {/* AL-10x10ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"AL - Land 10x10"}
                                  </s.TextDescription>
                                  {pcMinted_1 >= 65 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl3_1 == mintedAL3_1 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"AL Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL3_1 *
                                                  mintAmountAL3_1 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL3_1(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL3_1}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL3_1(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(3, 1);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL3_1 +
                                                  " MINT(10x10)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"a0b6842e-3ad8-4154-af6c-67d9e1ee0e9d"}?quantity=${mintAmountAL3_1}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=1&amountInWei=${500000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL3_1 +
                                                    " MINT(10x10)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL3_1}
                                            {"/"}
                                            {canMintAl3_1}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* AL-5x5ここから */}
                                  {/* AL-5x5ここから */}
                                  {/* AL-5x5ここから */}
                                  {/* AL-5x5ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"AL - Land 5x5"}
                                  </s.TextDescription>
                                  {pcMinted_2 >= 278 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl3_2 == mintedAL3_2 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"AL Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL3_2 *
                                                  mintAmountAL3_2 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL3_2(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL3_2}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL3_2(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(3, 2);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL3_2 +
                                                  " MINT(5x5)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"a0b6842e-3ad8-4154-af6c-67d9e1ee0e9d"}?quantity=${mintAmountAL3_2}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=2&amountInWei=${250000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL3_2 +
                                                    " MINT(5x5)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL3_2}
                                            {"/"}
                                            {canMintAl3_2}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* AL-3x3ここから */}
                                  {/* AL-3x3ここから */}
                                  {/* AL-3x3ここから */}
                                  {/* AL-3x3ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"AL - Land 3x3"}
                                  </s.TextDescription>

                                  {pcMinted_3 >= 1500 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl3_3 == mintedAL3_3 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"AL Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL3_3 *
                                                  mintAmountAL3_3 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL3_3(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL3_3}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL3_3(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(3, 3);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL3_3 +
                                                  " MINT(3x3)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"a0b6842e-3ad8-4154-af6c-67d9e1ee0e9d"}?quantity=${mintAmountAL3_3}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=3&amountInWei=${150000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL3_3 +
                                                    " MINT(3x3)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL3_3}
                                            {"/"}
                                            {canMintAl3_3}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* AL-1x1ここから */}
                                  {/* AL-1x1ここから */}
                                  {/* AL-1x1ここから */}
                                  {/* AL-1x1ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"AL - Land 1x1"}
                                  </s.TextDescription>
                                  {pcMinted_4 >= 15500 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl3_4 == mintedAL3_4 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"AL Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL3_4 *
                                                  mintAmountAL3_4 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL3_4(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL3_4}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL3_4(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(3, 4);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL3_4 +
                                                  " MINT(1x1)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"a0b6842e-3ad8-4154-af6c-67d9e1ee0e9d"}?quantity=${mintAmountAL3_4}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=4&amountInWei=${39000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL3_4 +
                                                    " MINT(1x1)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL3_4}
                                            {"/"}
                                            {canMintAl3_4}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* AL所有者の方ここまで */}
                          {/* AL所有者の方ここまで */}
                          {/* AL所有者の方ここまで */}
                          {/* AL所有者の方ここまで */}

                          {/* FCFS所有者の方ここから */}
                          {/* FCFS所有者の方ここから */}
                          {/* FCFS所有者の方ここから */}
                          {/* FCFS所有者の方ここから */}
                          {allowlistValid4 ? (
                            <>
                              {database.isAlEnabled4 ? (
                                <>
                                  {/* FCFS-20x20ここから */}
                                  {/* FCFS-20x20ここから */}
                                  {/* FCFS-20x20ここから */}
                                  {/* FCFS-20x20ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FCFS - Land 20x20"}
                                  </s.TextDescription>

                                  {pcMinted_0 >= 17 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl4_0 == mintedAL4_0 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FCFS Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL4_0 *
                                                  mintAmountAL4_0 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL4_0(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL4_0}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL4_0(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(4, 0);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL4_0 +
                                                  " MINT(20x20)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"5b3b6607-7f64-4948-b149-24da61959654"}?quantity=${mintAmountAL4_0}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=0&amountInWei=${1000000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL4_0 +
                                                    " MINT(20x20)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL4_0}
                                            {"/"}
                                            {canMintAl4_0}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* FCFS-10x10ここから */}
                                  {/* FCFS-10x10ここから */}
                                  {/* FCFS-10x10ここから */}
                                  {/* FCFS-10x10ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FCFS - Land 10x10"}
                                  </s.TextDescription>
                                  {pcMinted_1 >= 65 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl4_1 == mintedAL4_1 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FCFS Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL4_1 *
                                                  mintAmountAL4_1 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL4_1(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL4_1}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL4_1(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(4, 1);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL4_1 +
                                                  " MINT(10x10)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"5b3b6607-7f64-4948-b149-24da61959654"}?quantity=${mintAmountAL4_1}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=1&amountInWei=${500000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL4_1 +
                                                    " MINT(10x10)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL4_1}
                                            {"/"}
                                            {canMintAl4_1}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* FCFS-5x5ここから */}
                                  {/* FCFS-5x5ここから */}
                                  {/* FCFS-5x5ここから */}
                                  {/* FCFS-5x5ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FCFS - Land 5x5"}
                                  </s.TextDescription>
                                  {pcMinted_2 >= 278 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl4_2 == mintedAL4_2 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FCFS Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL4_2 *
                                                  mintAmountAL4_2 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL4_2(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL4_2}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL4_2(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(4, 2);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL4_2 +
                                                  " MINT(5x5)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"5b3b6607-7f64-4948-b149-24da61959654"}?quantity=${mintAmountAL4_2}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=2&amountInWei=${250000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL4_2 +
                                                    " MINT(5x5)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL4_2}
                                            {"/"}
                                            {canMintAl4_2}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* FCFS-3x3ここから */}
                                  {/* FCFS-3x3ここから */}
                                  {/* FCFS-3x3ここから */}
                                  {/* FCFS-3x3ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FCFS - Land 3x3"}
                                  </s.TextDescription>
                                  {pcMinted_3 >= 1500 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl4_3 == mintedAL4_3 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FCFS Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL4_3 *
                                                  mintAmountAL4_3 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL4_3(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL4_3}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL4_3(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(4, 3);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL4_3 +
                                                  " MINT(3x3)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"5b3b6607-7f64-4948-b149-24da61959654"}?quantity=${mintAmountAL4_3}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=3&amountInWei=${150000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL4_3 +
                                                    " MINT(3x3)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL4_3}
                                            {"/"}
                                            {canMintAl4_3}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}

                                  {/* FCFS-1x1ここから */}
                                  {/* FCFS-1x1ここから */}
                                  {/* FCFS-1x1ここから */}
                                  {/* FCFS-1x1ここから */}
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"FCFS - Land 1x1"}
                                  </s.TextDescription>

                                  {pcMinted_4 >= 15500 ? (
                                    <s.Container
                                      ai={"center"}
                                      jc={"center"}
                                      fd={"row"}
                                    >
                                      <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"SOLD OUT!"}
                                      </s.TextDescription>
                                    </s.Container>
                                  ) : (
                                    <>
                                      {canMintAl4_4 == mintedAL4_4 ? (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                              }}
                                            >
                                              {"FCFS Minted."}
                                            </s.TextDescription>
                                          </s.Container>
                                        </>
                                      ) : (
                                        <>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                fontSize: "1.4rem",
                                              }}
                                            >
                                              {Math.round(
                                                CONFIG.DISPLAY_COSTAL4_4 *
                                                  mintAmountAL4_4 *
                                                  1000
                                              ) / 1000}
                                              ETH
                                            </s.TextDescription>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledIncDecButton
                                              style={{ lineHeight: 0.4 }}
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                decrementMintAmountAL4_4(1);
                                                getData();
                                              }}
                                            >
                                              ー
                                            </StyledIncDecButton>
                                            <s.SpacerMedium />
                                            <s.TextDescription
                                              style={{
                                                textAlign: "center",
                                                color: "var(--accent-text)",
                                                padding: 20,
                                              }}
                                            >
                                              {mintAmountAL4_4}
                                            </s.TextDescription>
                                            <s.SpacerMedium />
                                            <StyledIncDecButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                incrementMintAmountAL4_4(1);
                                                getData();
                                              }}
                                            >
                                              ＋
                                            </StyledIncDecButton>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledButton
                                              disabled={claimingNft ? 1 : 0}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                claimNFTsAl(4, 4);
                                                getData();
                                              }}
                                            >
                                              {claimingNft
                                                ? "MINTING.."
                                                : mintAmountAL4_4 +
                                                  " MINT(1x1)"}
                                            </StyledButton>
                                          </s.Container>
                                          <s.Container
                                            ai={"center"}
                                            jc={"center"}
                                            fd={"row"}
                                          >
                                            <StyledLink
                                              target={"_blank"}
                                              // href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                              //   blockchain.account
                                              // }`}
                                              href={`https://paypiement.xyz/projects/${"5b3b6607-7f64-4948-b149-24da61959654"}?quantity=${mintAmountAL4_4}&recipientAddress=${
                                                blockchain.account
                                              }&pcId=4&amountInWei=${39000000000000000}`}
                                            >
                                              <StyledButton
                                                bgImage={
                                                  !claimingNft &&
                                                  "/config/images/credit.png"
                                                }
                                                disabled={claimingNft ? 1 : 0}
                                                onClick={(e) => {
                                                  // e.preventDefault();
                                                }}
                                              >
                                                {claimingNft
                                                  ? "MINTING.."
                                                  : mintAmountAL4_4 +
                                                    " MINT(1x1)"}
                                              </StyledButton>
                                            </StyledLink>
                                          </s.Container>
                                          <s.SpacerLarge />
                                          <s.TextDescription
                                            style={{
                                              textAlign: "center",
                                              color: "var(--accent-text)",
                                            }}
                                          >
                                            {"("}
                                            {mintedAL4_4}
                                            {"/"}
                                            {canMintAl4_4}
                                            {")"}
                                          </s.TextDescription>
                                          <s.SpacerLarge />
                                          <s.SpacerLarge />
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* FCFS所有者の方ここまで */}
                          {/* FCFS所有者の方ここまで */}
                          {/* FCFS所有者の方ここまで */}
                          {/* FCFS所有者の方ここまで */}

                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}

                          {allowlistValid0 && (
                            <>
                              {!database.isAlEnabled0 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    FREE MINT 2023/11/22 20:00(JST)
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    YOU HAVE ({canMintAl0}) FREE 1x1
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid1 && (
                            <>
                              {!database.isAlEnabled1 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    FREE MINT 2023/11/22 20:00(JST)
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    YOU HAVE ({canMintAl1}) FREE 3x3
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid2 && (
                            <>
                              {!database.isAlEnabled2 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    FREE MINT 2023/11/22 20:00(JST)
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    YOU HAVE ({canMintAl2}) FREE 5x5
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid3 && (
                            <>
                              {!database.isAlEnabled3 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    YOU HAVE AL:2023/11/23 20:00(JST)
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl3_0})20x20
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl3_1})10x10
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl3_2})5x5
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl3_3})3x3
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl3_4})1x1
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}
                          {allowlistValid4 && (
                            <>
                              {!database.isAlEnabled4 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    YOU HAVE FCFS:2023/11/24 20:00(JST)
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl4_0})20x20
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl4_1})10x10
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl4_2})5x5
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl4_3})3x3
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    CAN MINT ({canMintAl4_4})1x1
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )}

                          {/* <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            PUBLIC 2023/08/06 TBD
                          </s.TextDescription>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            PUBLIC 2023/08/07 TBD
                          </s.TextDescription> */}

                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}

                          <s.SpacerMedium />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            NFT Market Place
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.MARKETPLACE_LINK}
                            >
                              {CONFIG.MARKETPLACE}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerLarge />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButtonReload
                              onClick={(e) => {
                                e.preventDefault();
                                getReload();
                              }}
                            >
                              {claimingNft ? "Busy" : "RELOAD"}
                            </StyledButtonReload>
                          </s.Container>
                        </s.Container>

                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {blockchain.account === "" ||
                        blockchain.smartContract === null ||
                        blockchain.account === undefined ? (
                          <></>
                        ) : (
                          <>
                            <OperationContainer
                              flex={6}
                              jc={"center"}
                              ai={"center"}
                            >
                              {"0x637d25D0769f747B2742A04d249802dA85395970" ==
                              blockchain.account ? (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    style={{
                                      position: "fixed",
                                      top: "1rem",
                                      left: "1rem",
                                      width: "10rem",
                                    }}
                                  >
                                    {!database.isAlEnabled0 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(0, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "FREE1x1.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(0, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "Busy"
                                            : "FREE1x1.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled1 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(1, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "FREE3x3.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(1, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "Busy"
                                            : "FREE3x3.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled2 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(2, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "FREE5x5.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(2, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "Busy"
                                            : "FREE5x5.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled3 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(3, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "AL.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(3, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "AL.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled4 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(4, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "FCFS.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(4, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "FCFS.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}

                                    {!data.isPublicSaleEnabled ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setPublicSaleEnable(true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "PB.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setPublicSaleEnable(false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "PB.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    <StyledButtonOparator
                                      onClick={(e) => {
                                        e.preventDefault();
                                        withdraw();
                                        getData();
                                      }}
                                    >
                                      {claimingNft ? "Busy" : "WITHDRAW"}
                                    </StyledButtonOparator>

                                    {allowlistRoot0 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(0);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "フリー1x1登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot1 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "フリー3x3登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot2 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(2);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "フリー5x5登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot3 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(3);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "AL登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot4 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(4);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "FCFS登録"}
                                      </StyledButtonOparator>
                                    )}
                                  </s.Container>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    style={{
                                      position: "fixed",
                                      top: "3rem",
                                      right: "1rem",
                                      width: "10rem",
                                    }}
                                  >
                                    <StyledButtonOparator
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setBaseUrl();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "Busy"
                                        : "リビールURLセット"}
                                    </StyledButtonOparator>
                                    {revealNum !== false && (
                                      <>
                                        <StyledInput
                                          type="text"
                                          value={revealNum}
                                          onChange={(e) => {
                                            e.preventDefault();
                                            changeRevealNum(e);
                                          }}
                                        ></StyledInput>
                                        <StyledButtonOparator
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setReveal();
                                            getData();
                                          }}
                                        >
                                          {claimingNft
                                            ? "Busy"
                                            : `リビール制限(${revealNum})`}
                                        </StyledButtonOparator>
                                      </>
                                    )}
                                  </s.Container>
                                </>
                              ) : (
                                <></>
                              )}
                            </OperationContainer>
                          </>
                        )}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                      </span>
                    </MainContainer>
                  </s.Container>
                </>
              )}
            </>
          )}
        </ResponsiveWrapper>
      </s.Container>
      {/* </>
      )} */}
    </s.Screen>
  );
}

export default Home;
